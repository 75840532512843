.player-wrapper {
    position: relative;
    width: 100%;
    height: 95vh;
    padding-top: 56.25%;
    display: flex;
    flex-direction: row;
}
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
.player-title{
    z-index: 100;
}
.right-nav{
    width: 100%;
    height: 100vh;
    margin: 3px 3px;
    overflow-x: hidden;
    overflow-y: auto;
}

a {
    font-size:14px;
    font-weight:700
  }
  .superNav {
    font-size:13px;
  }
  .form-control {
    outline:none !important;
    box-shadow: none !important;
  }
  .v-container{
      min-height:350px;
  }
  .pointer{
    cursor: pointer;
  }
  .underline{
    border-bottom: 1px solid #555;
  }
  main{
    min-height: 400px;
    background-color: #FFF;
  }
  main .table-responsive{
    min-height: 75vh;
  }
  /* FORM */
  .form-container{
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .react-dropdown-tree-select .dropdown .dropdown-trigger {
    width: 100%;
    display: flex;
  }
  .react-dropdown-tree-select .dropdown .dropdown-trigger .search{
    width: 100%;
  }
  .react-dropdown-tree-select .dropdown {
    width: 100%;
  }
  @media screen and (max-width:540px){
    .centerOnMobile {
      text-align:center
    }
  }